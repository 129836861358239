<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>MIS ACTIVIDADES</b></h4>
          </template>
          <div class="px-5 py-3">
            <div class="row" style="gap: 1rem">
              <div class="col">
                <!-- linar-style cursor glow -->
                <CardTable :data="dataCards" />
              </div>
            </div>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table id="datatable_misActividades"
              class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>% Avance Evaluado</th>
                  <th>% Avance Jefe</th>
                  <th>Comentarios Jefe</th>
                  <th>Detalles</th>
                  <th>Estado Actividad</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listaItems" :key="index">
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.actividadProgreso.porcentajeAut }}%</td>
                  <td>{{ item.actividadProgreso.porcentajeResp }}%</td>
                  <td>{{ item.actividadProgreso.comentarioResp }}</td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <b-button class="d-flex" v-b-modal.detailsAct @click="detalleActividad(item)">
                        <i class="fas fa-eye"></i></b-button>
                    </div>
                  </td>
                  <td>
                    <h4>
                      <b-badge :variant="`${item.actividadProgreso.porcentajeResp == 100
                        ? 'success'
                        : 'warning'
                        }`">
                        <i :class="`fas ${item.actividadProgreso.porcentajeResp == 100
                          ? 'fa-check'
                          : 'fa-clock'
                          }`"></i>&nbsp;
                        {{
                          item.actividadProgreso.porcentajeResp == 100
                            ? "Terminada"
                            : "Pendiente"
                        }}
                      </b-badge>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <b-modal id="detailsAct" size="xl" v-model="modalShowDetailsCap" hide-footer>
            <div>
              <b-card bg-variant="dark" v-if="detailActividad.actividadProgreso">
                <div class="row flex-column-reverse flex-lg-row">
                  <!-- data info activity -->

                  <div class="col-12 col-lg">
                    <div class="d-flex align-items-center">
                      <div style="
                          background-color: #df122e;
                          width: 7px;
                          border-radius: 0 20px 0 20px;
                          height: 30px;
                        "></div>
                      <span class="h2 font-weight-bold mb-0" v-b-tooltip="{
                        title: `Actividad asignada`,
                        placement: 'bottom',
                        variant: 'primary',
                      }" style="
                          font-size: 1.5rem;
                          font-weight: bolder;
                          margin-left: 5px;
                          max-width: 100%;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        ">
                        {{ detailActividad.actividadSelected.nombre }}
                      </span>
                    </div>
                    <!-- access info -->
                    <div class="d-flex align-items-center flex-wrap mt-3" style="gap: 1.5rem">
                      <!-- duracion -->
                      <div class="d-flex align-items-center col-5">
                        <div class="d-flex justify-content-center align-items-center mr-2">
                          <i class="far fa-calendar-check" style="font-size: 1.5rem"></i>
                        </div>
                        <div class="d-flex flex-column">
                          <small class="font-weight-bold text-white-50">Fecha inicio</small>
                          <span style="font-weight: bold; font-size: 1.1rem">{{ detailActividad.fechaInicio | formatDate
                            }}
                          </span>
                        </div>
                      </div>

                      <!-- divisor -->
                      <div style="
                          width: 1px;
                          height: 20px;
                          background-color: #102e5f;
                        "></div>

                      <!-- fecha inicio -->
                      <div class="d-flex align-items-center col-5">
                        <div class="d-flex justify-content-center align-items-center mr-2">
                          <i class="fa-regular fa-calendar-xmark" style="font-size: 1.5rem"></i>
                        </div>
                        <div class="d-flex flex-column">
                          <small class="font-weight-bold text-white-50">Fecha fin</small>
                          <span style="font-size: 1.1rem" class="font-weight-bold">
                            {{ detailActividad.fechaFin | formatDate }}</span>
                        </div>
                      </div>

                      <!-- fecha último avance -->
                      <div class="d-flex align-items-center col-12">
                        <div class="d-flex justify-content-center align-items-center mr-2">
                          <i class="fa-regular fa-calendar-plus" style="font-size: 1.5rem"></i>
                        </div>
                        <div class="d-flex flex-column">
                          <small class="font-weight-bold text-white-50">Fecha último avance</small>
                          <span style="font-size: 1.1rem" class="font-weight-bold">
                            {{
                              detailActividad.fechaCreacion | timeSince
                            }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- descripcion -->
                    <div class="d-flex justify-content-center col-12 mt-4">
                      <b-card class="col-11 mb-1">
                        <div class="position-absolute" style="left: -32px; top: -0px">
                          <i class="fa-solid fa-chart-line mr-2" style="font-size: 1.5rem"></i>
                          <span class="h5 font-weight-bold">Descripción plan de mejora</span>
                        </div>
                        <div class="content-message mt-3">
                          {{
                            detailActividad.descripcionPlan
                              ? detailActividad.descripcionPlan
                              : "No hay ninguna descripción para este plan de mejora"
                          }}
                        </div>
                      </b-card>
                    </div>
                    <!-- descripcion actividad -->
                    <div class="d-flex justify-content-center col-12 mt-4">
                      <b-card class="col-11 mb-1">
                        <div class="position-absolute" style="left: -32px; top: -0px">
                          <i class="fa-solid fa-file-lines mr-2" style="font-size: 1.5rem"></i>
                          <span class="h5 font-weight-bold">Descripción Actividad</span>
                        </div>
                        <div class="content-message mt-3">
                          {{
                            detailActividad.descripcionPlan
                              ? detailActividad.descripcionPlan
                              : "No hay ninguna descripción para este plan de mejora"
                          }}
                        </div>
                      </b-card>
                    </div>
                    <!-- otros datos -->
                    <div class="d-flex align-items-center mt-4 flex-wrap" style="gap: 2rem" v-if="
                      detailActividad.actividadSelected.usuarioResponsable
                    ">
                      <!-- instructor  -->
                      <div class="d-flex align-items-center" v-b-tooltip="{
                        title: `Responsable Onboarding`,
                        placement: 'bottom',
                        variant: 'primary',
                      }">
                        <i class="far fa-user mr-2" style="font-size: 1rem"></i>
                        <div class="d-flex flex-column font-weight-bold">
                          <small class="text-white-50">Responsable</small>
                          <span>
                            {{
                              detailActividad.actividadSelected
                                .usuarioResponsable.nombres +
                              " " +
                              detailActividad.actividadSelected
                                .usuarioResponsable.apellidos
                            }}
                          </span>
                        </div>
                      </div>
                      <!-- empresa  -->
                      <div class="d-flex align-items-center" v-b-tooltip="{
                        title: `Porcentaje aprobación`,
                        placement: 'bottom',
                        variant: 'primary',
                      }">
                        <i class="fa-solid fa-percent mr-2" style="font-size: 1rem"></i>
                        <div class="d-flex flex-column font-weight-bold">
                          <small class="text-white-50">Objetivo</small>

                          <span>{{
                            detailActividad.actividadSelected.porcentajeCierre
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center col-12 mt-4">
                      <b-card class="col-11">
                        <div class="position-absolute" style="left: -32px; top: -0px">
                          <i class="fa-regular fa-comments mr-2" style="font-size: 1.5rem"></i>
                          <span class="h5 font-weight-bold">Mis comentarios</span>
                        </div>
                        <div class="content-message mt-3">
                          {{
                            detailActividad.actividadProgreso.comentarioAut
                              ? detailActividad.actividadProgreso.comentarioAut
                              : "No has realizado ningún comentario."
                          }}
                        </div>
                      </b-card>
                    </div>
                    <!-- actions user -->
                    <div class="d-flex flex-column" style="gap: 20px">
                      <!-- registrar avance -->
                      <b-button v-b-toggle="`collapse-1-inner`" class="mt-2">Registrar avance <i
                          class="fas fa-flag-checkered"></i></b-button>
                      <b-collapse :id="`collapse-1-inner`" class="mt-2">
                        <b-card>
                          <form @submit.prevent="
                            sendActividadProgresoTrabajador(
                              detailActividad.actividadSelected
                            )
                            ">
                            <div class="d-flex flex-column justify-content-between" style="gap: 20px">
                              <b-form-textarea class="w-100" v-model="actividadProgresoTrabajador.comentarioAut
                                " placeholder="Escriba un comentario..." minlength="10" maxlength="255"
                                required></b-form-textarea>
                              <div class="d-flex justify-content-center align-items-center">
                                <input class="mr-1 w-100" type="range" id="porcentaje" v-model="detailActividad.actividadProgreso
                                  .porcentajeAut
                                  " @input="detailActividad.changeInput = true" min="0" max="100" step="0" />
                                <span style="font-size: 30px" :class="`px-2 ${detailActividad.actividadProgreso
                                  .porcentajeAut < 50
                                  ? 'text-danger'
                                  : detailActividad.actividadProgreso
                                    .porcentajeAut < 80
                                    ? 'text-warning'
                                    : 'text-primary'
                                  }`"><small>{{
                                    detailActividad.actividadProgreso
                                      .porcentajeAut
                                  }}%</small></span>
                              </div>
                            </div>
                            <div class="text-right mt-3">
                              <b-button block type="submit" variant="outline-light"
                                :disabled="!detailActividad.changeInput">
                                <i class="fas fa-flag-checkered"></i> Registrar
                              </b-button>
                            </div>
                          </form>
                        </b-card>
                      </b-collapse>
                    </div>
                  </div>
                  <!-- grafic progress confirmacion asistentes  -->
                  <div class="col-12 col-lg">
                    <b-card class="banner-content" style="height: 100%">
                      <div class="d-flex flex-column justify-content-center align-items-center" style="height: 100%">
                        <!-- component ProgressChart -->
                        <div class="d-flex flex-column justify-content-center align-items-center">
                          <span class="h3 font-weight-bold mt-3 mb-1 text-uppercase">
                            Progreso General
                          </span>
                          <ProgressChart :total="detailActividad.actividadSelected.porcentajeCierre
                            " :confirmed="detailActividad.actividadProgreso.porcentajeAut
                              " />
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </b-card>
            </div>
          </b-modal>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import CardTable from "../../../components/cardTable/CardTable.vue";
import { core } from "../../../config/pluginInit";
import moment_timezone from "moment-timezone";
const Swal = require("sweetalert2");

import moment from "moment/moment";
import "moment/locale/es";
moment.locale("es");
import ProgressChart from "@/components/charts/ProgressChart.vue";

export default {
  name: "MisActividades",
  data() {
    return {
      listaItems: [],
      listaPlanes: [],
      persona: {},
      actividadProgresoTrabajador: {
        planYMejoramientoId: null,
        actividadId: null,
        porcentajeAut: 0,
        comentarioAut: "",
        porcentajeResp: 0,
        comentarioResp: "",
        fechaCreacion: null,
        fechaCierre: null,
      },
      detailActividad: {
        fechaInicio: null,
        fechaFin: null,
        fechaCreacion: null,
        descripcionPlan: null,
        descripcionActividad: null,
        usuarioResponsable: null,
        actividadSelected: {},
        actividadProgreso: {},
        changeInput: false,
      },
      dataCards: [
        {
          title: "Actividades con avances",
          value: () =>
            this.listaItems.filter(
              (item) => item.actividadProgreso.porcentajeAut > 0
            ).length,
          iconName: "bolt",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        {
          title: "Nuevas actividades",
          value: () => {
            const fechaActual = new Date(); // Obtener la fecha actual
            fechaActual.setDate(fechaActual.getDate() - 3); // Restar tres días

            return this.listaItems.filter((item) => {
              const fechaCreacion = new Date(
                item.actividadProgreso.fechaCreacion
              );
              return fechaCreacion > fechaActual;
            }).length;
          },
          iconName: "calendar-plus",
          color: "rgba(223, 18, 46, 1)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getData();
    core.index();
    if (window.$.fn.DataTable.isDataTable("#datatable_misActividades")) {
      window.$("#datatable_misActividades").DataTable().destroy();
    }
    this.dataTableInstance = window.$("#datatable_misActividades").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  methods: {
    hideModal() {
      if (this.actividadProgresoTrabajador.comentarioAut !== null) {
        this.actividadProgresoTrabajador.comentarioAut = "";
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PlanYMejoramiento/ByPersonaId/" + this.persona.id,
        });
        console.log("list planMejoramiento...", res);
        if (res.length > 0) {
          this.listaPlanes = res;
          const data = await this.fetchAllItemsParallel(res);
          console.log("------------------->", data);
          this.listaItems = data;
        } else {
          this.listaPlanes = [];
        }
      } catch (error) {
        this.listaPlanes = [];
        console.log("err", error);
      }
    },
    detalleActividad(item) {
      const {
        actividadProgreso,
        fechaInicio,
        fechaFin,
        descripcionPlan,
        descripcion,
        usuarioResponsable,
      } = item;
      this.detailActividad = {
        actividadSelected: item,
        actividadProgreso: actividadProgreso,
        fechaInicio,
        fechaCreacion: actividadProgreso.fechaCreacion,
        fechaFin,
        descripcionPlan,
        descripcionActividad: descripcion,
        usuarioResponsable: `${usuarioResponsable.nombres} ${usuarioResponsable.apellidos}`,
      };
      console.log("----------->", this.detailActividad);
    },
    async sendActividadProgresoTrabajador(actividad) {
      try {
        let data = {
          ...this.actividadProgresoTrabajador,
          planYMejoramientoId: actividad.planId,
          actividadId: actividad.id,
          comentarioAut: this.actividadProgresoTrabajador.comentarioAut,
          porcentajeAut: actividad.actividadProgreso.porcentajeAut,
          fechaCreacion: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
          fechaCierre: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
        };
        let res = await this.$store.dispatch("hl_post", {
          path: "Actividad/CreateActividadProgresoTrabajador/",
          data,
        });
        console.log(res);
        if (res) {
          // Actualiza el progreso de la actividad en el cliente
          this.detailActividad.fechaCreacion = moment_timezone(
            new Date()
          ).format("YYYY-MM-DDTHH:mm:ss");
          this.detailActividad.actividadProgreso.comentarioAut =
            this.actividadProgresoTrabajador.comentarioAut;
          Swal.fire({
            title: "Listo!",
            text: "Avance registrado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              console.log("confirmado");
              this.actividadProgresoTrabajador.comentarioAut = "";
              this.detailActividad.changeInput = false;
            }
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getActividadById(actividadId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Actividad/GetActividad/" + actividadId,
        });
        console.log("actividadById...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async fetchAllItemsParallel(data) {
      const promises = [];
      for (let item of data) {
        let misActividades = item.actividadesId.split(",");
        for (let id of misActividades) {
          promises.push(
            this.getActividadById(id).then(async (actividad) => {
              let actividadProgresoResult =
                await this.getActividadByPlanIdAndActividadId(
                  item.id,
                  actividad.id
                );
              actividadProgresoResult.changeInput = false;
              return {
                ...actividad,
                fechaInicio: item.fechaInicio,
                fechaFin: item.fechaFin,
                descripcionPlan: item.descripcion,
                planId: item.id,
                actividadProgreso: actividadProgresoResult,
              };
            })
          );
        }
      }
      const results = await Promise.all(promises);
      return results;
    },
    async getActividadByPlanIdAndActividadId(planId, actividadId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "ActividadProgreso/GetActividadByPlanIdAndActividadId/" +
            planId +
            "/" +
            actividadId,
        });
        console.log("ActividadProgreso...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  filters: {
    formatDate(date) {
      return date
        ? moment(date).locale("es").format("D MMM YYYY")
        : "No hay fecha registrada";
    },
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("D MMM YYYY hh:mm A");
    },
  },
  components: { CardTable, ProgressChart },
};
</script>

<style></style>
